import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  "pt": {
    translation: {
      errorMessages: {
        "domainError": "Domínio não existe ou já está sendo usado.",
        "tokenError": "Token inválido, verifique o escopos do app.",
        "planError": "Plano Shopify inválido, selecione um plano pago.",
      },
      authentication: {
        login: {
          "email": "E-mail",
          "emailError": "E-mail inválido",
          "password": "Senha",
          "remember": "Lembrar desse dispositivo",
          "forgotPassword": "Esqueceu sua senha?",
          "enter": "Entrar",
          "passwordMessage": "Mínimo de 8 caracteres"
        },
        emailVerification: {
          "hello": "Olá!",
          "redirect": "Você será redirecionado!"
        },
        error: {
          "notFound": "A página não existe!",
          "back": "Voltar"
        },
        passwordResetSent: {
          "title": "Deu certo!",
          "description": "O link para criar uma nova senha já foi enviado para seu email!",
          "back": "Voltar para Login"
        },
        forgotPassword: {
          "title": "Esqueceu sua senha?",
          "description": "Por favor, insira o email associado a sua conta e nós iremos encaminhar um email com o link para você criar uma nova senha.",
          "changePassword": "Alterar a Senha",
          "back": "Voltar para Login"
        },
        resetPassword: {
          "title": "Redefinir sua senha",
          "description": "Para redefinir sua senha, crie uma nova senha de no mínimo 8 caracteres.",
          "insertPassword": "Insira sua nova senha",
          "passwordMessage": "Mínimo de 8 caracteres",
          "confirmPasswordMessage": "A senha nova não confere com a anterior",
          "confirmPassword": "Confirme sua nova senha",
          "redefinePassword": "Redefinir Senha"
        },
        signUp: {
          "welcomeTo": "Bem-vindo(a) à",
          "hasAccount": "Já possui uma conta?",
          "enter": "Entrar",
          "back": "Voltar",
          "next": "Próximo",
          "finish": "Finalizar",
          "confirmPassword": "Confirme a senha",
          "passwordMessage": "Mínimo de 8 caracteres"
        },
        signUpForm: {
          "cta": "QUERO PARTICIPAR",
          "invalidEmail": "Invalid email!",
          "name": "Nome Completo*",
          "email": "Email*",
          "phone": "Telefone*",
          "invalidMail": "Invalid E-mail",
          "whatsapp": "Whatsapp com DDD*",
          "privacy": "Ao informar meus dados, eu concordo com a Política de privacidade."
        }
      },
      typ: {
        title: "Obrigado por escolher o {{builderName}}! Estamos empolgados por tê-lo conosco.",
        p1: "Você está a apenas 30 minutos de lançar sua loja pré-construída",
        p2: "Em breve, você receberá as credenciais necessárias para acessar a plataforma por e-mail",
        button: "Entrar",
        getStarted: "Como Começar:",
        point1: "Clique no botão 'Entrar' para acessar a plataforma Shop Build Pro.",
        point2: "Use as credenciais enviadas para o seu endereço de e-mail para fazer login na plataforma.",
        point3: "Você encontrará um guia abrangente passo a passo projetado para guiá-lo no processo de configuração da sua loja. É simples, rápido e destinado a fazer sua loja funcionar em menos de 30 minutos."
      },
      store: {
        "welcome": {
          "title": "Vamos criar sua loja online!",
          "paragraph": "Que bom ter você aqui! Em apenas 3 passos simples, você terá sua própria loja profissional, pronta para começar a vender. Assista este vídeo rápido e descubra como!",
          "button": "Vamos lá!"
        },
        "wizard": {
          "pageTitle": "Criador de lojas",
          "pageDescription": "Sua loja pronta para vender em 15 minutos!",
          "continue": "Continuar",
          "back": "Voltar",
          "steps": {
            "niche": "Nicho",
            "palette": "Paleta",
            "banner": "Banner",
            "account": "Conta",
            "plan": "Plano",
            "store": "Loja",
            "shopify": "Criar conta",
            "app": "Instalar app",
            "success": "Começar a usar"
          },
          "pages": {
            "niche": {
              "title": "Escolha a categoria da sua loja",
              "miscellaneous": "Diversos",
              "fitness": "Fitness",
              "health": "Saúde",
              "tech": "Tecnologia",
              "pet": "Pet",
              "kids": "Infantil",
              "home": "Casa e Cozinha"
            },
            "palette": {
              "title": "Escolha uma combinação para sua loja"
            },
            "banner": {
              "title": "Escolha o fundo para o banner da sua página inicial"
            },
            "account": {
              "queue": {
                "title": "Você está na fila!",
                "estimated": "Tempo estimado de espera:",
                "minute": "1 minuto",
                "hold": "Mantenha esta página aberta e não a feche até que a criação esteja concluída.",
                "warning": "Mantenha esta página aberta e não a feche até que a criação esteja concluída!"
              },
              "install": {
                "title": "Sua conta está sendo criada automaticamente",
                "message": "Em menos de 10 minutos, sua conta estará pronta. Seu caminho para o sucesso está apenas começando.",
                "installMessage1": "Inicializando...",
                "installMessage2": "Configurando os dados da conta...",
                "installMessage3": "Inserindo e-mail e senha...",
                "installMessage4": "Configurando idiomas e moeda...",
                "installMessage5": "Criando conta na Shopify...",
                "installMessage6": "Criando nova loja na Shopify...",
                "installMessage7": "Salvando domínio da loja...",
                "installMessage8": "Criando conexão com a loja...",
                "installMessage9": "Habilitando os escopos necessários...",
                "installMessage10": "Verificando as configurações...",
                "installMessage11": "Salvando informações de login...",
                "installMessage12": "Estamos quase lá...",
                "installMessage13": "Finalizando...",
                "warning": "Mantenha esta página aberta e não a feche até que a criação esteja concluída!"
              }
            },
            "plan": {
              "warning": "Mantenha esta página aberta e não a feche até que a criação esteja concluída!",
              "title": "Assista ao vídeo abaixo para dar os próximos passos e desbloquear seu curso completo gratuitamente.",
              "credentials": "Credenciais",
              "email": "E-mail:",
              "password": "Senha:",
              "url": "URL:",
              "button": "Acessar o plano",
              "modal": {
                "error": {
                  "title": "Erro ao verificar o plano",
                  "description": "O plano ainda não foi ativo! Verifique o plano ou aperte voltar para acessar os dados de acesso.",
                  "button": "Verificar o plano",
                  "back": "Voltar"
                },
                "success": {
                  "title": "Plano verificado com sucesso",
                  "button": "Continuar"
                },
                "verify": {
                  "title": "Verifique o plano",
                  "description": "Após contratar o plano, clique no botão abaixo para realizarmos a verificação.",
                  "button": "Verificar o plano"
                },
                "verifying": {
                  "title": "Verificando o plano...",
                }
              },
              "shopify": {
                "title": "Você acabou de ganhar 3 dias para testar sua loja na Shopify.",
                "description": "Ative o plano e garanta a promoção pagando apenas $1 no primeiro mês."
              }
            },
            "store": {
              "queue": {
                "title": "Você está na fila!",
                "minute": "1 minuto",
                "estimated": "Tempo estimado de espera:",
                "hold": "Mantenha esta página aberta e não a feche até que a criação esteja concluída.",
                "warning": "Mantenha esta página aberta e não a feche até que a criação esteja concluída!"
              },
              "install": {
                "title": "Instalação do tema e produto",
                "message": "Em menos de 3 minutos, sua loja estará pronta. Seu caminho para o sucesso está apenas começando.",
                "warning": "Mantenha esta página aberta e não a feche até que a criação esteja concluída!",
                "installMessage1": "Inicializando...",
                "installMessage2": "Instalando tema da loja...",
                "installMessage3": "Configurando a paleta de cores...",
                "installMessage4": "Configurando os banners...",
                "installMessage5": "Criando páginas de termos e políticas...",
                "installMessage6": "Criando as categorias...",
                "installMessage7": "Instalando produtos..."
              }
            },
            "shopify": {
              "title": "Passo 1: Vamos criar sua conta na Shopify",
              "firstTitle": "1️⃣ Siga estas instruções simples:",
              "firstLi1": "Clique no botão “Abrir Shopify abaixo",
              "firstLi2": "Uma nova janela vai abrir",
              "firstLi3": "Mantenha esta página aberta também",
              "shopifyButton": "Abrir Shopify",
              "secondTitle": "2️⃣ Criar sua conta",
              "secondLi1": "Digite seu email (use um que nunca usou na Shopify)",
              "secondLi2": 'Clique em "Iniciar teste gratuito"',
              "secondAlert1": "Ao parecer a opção de país, lembre-se de selecionar ",
              "secondAlert2": "Do contrário sua loja não será configurada corretamente",
              "thirdTitle": "3️⃣ Configuração rápida",
              "thirdLi1": 'Sempre que ver "Pular", pode clicar',
              "thirdLi2": 'Escolha "Continuar com Email"',
              "thirdLi3": 'Crie sua senha',
              "fourthTitle": " 4️⃣ ⁠Clique no botão \"Selecionar plano\" na Shopify",
              "fifthTitle": "5️⃣ ⁠Escolha o plano \"Basic\" (Mensal)",
              "fifthLi1": "⁠Você terá 3 dias de teste gratuitos",
              "fifthLi2": "Após o teste, os 3 primeiros meses saem por apenas R$6",
              "sixthTitle": "6️⃣ ⁠Ao solicitar o endereço do seu negócio, pesquise rapidamente no Google por um endereço qualquer do país escolhido",
              "sixthLi1": "⁠Não se preocupe, este endereço não é público",
              "seventhTitle": "7️⃣ ⁠⁠Insira suas informações de pagamento (Cartão de Crédito)",
              "eigthTitle": "8️⃣ ⁠Clique em \"Assinar\" para confirmar o plano",
              "eigthAlert": "Observação: Sem a ativação do plano, não podemos customizar sua loja, remover a senha ou publicar produtos para venda. É um passo obrigatório para avançar.",
              "ninethTitle": "9️⃣ Pronto",
              "ninethLi1": "Volte para esta página",
              "ninethLi2": "Clique no botão \"Continuar\" abaixo"
            },
            "app": {
              "title": "Passo 2: Vamos instalar o ProStore AI",
              "firstTitle": "1️⃣ Instalar o app",
              "firstLi1": 'Clique no botão "Instalar app abaixo"',
              "firstLi2": "Uma nova janela vai abrir",
              "firstLi3": "Mantenha esta página aberta também",
              "shopifyButton": "Instalar ProStore AI",
              "secondTitle": "2️⃣ Confirmar instalação",
              "secondLi1": 'Na nova janela, clique em "Instalar"',
              "secondLi2": 'Aguarde alguns segundos',
              "thirdTitle": "3️⃣ Seu código especial",
              "thirdLi1": 'Clique no código acima para copiar',
              "thirdLi2": 'Cole o código no app ProStore AI',
              "thirdLi3": 'Pronto! ✨',
              "thirdAlert": "Esse é um código único. Salve-o em algum lugar seguro. Caso precise de ajuda do nosso suporte, esse código poderá ser solicitado.",
              "fourthTitle": " 4️⃣ Finalizar",
              "fourthLi1": "Volte para esta página",
              "fourthLi2": 'Clique no botão "Continuar" abaixo',
              "codeCopied": 'Código copiado com successo!'
            },
            "success": {
              "title": "Passo 3: Sua loja está quase pronta! 🎉",
              "description": "Parabéns! Agora assista ao vídeo abaixo para entender como personalizar sua loja:",
              "support": "Falar com o suporte",
              "app": "Acessar App"
            }
          }
        }
      },
      contact: {
        title: "Precisa de ajuda? Entre em contato",
        text: "Nossa equipe de suporte pode ajudá-lo com qualquer dúvida relacionada à criação da sua loja. Sinta-se à vontade para perguntar qualquer coisa; nosso tempo médio de resposta é de 2 horas durante o horário comercial nos dias úteis",
        name: "Nome",
        email: "E-mail",
        message: "Mensagem",
        button: "Enviar",
        success: "Mensagem enviada com sucesso!",
        successText: "Em breve você receberá um e-mail de resposta. Obrigado!"
      }
    }
  },
  "es": {
    translation: {
      authentication: {
        "login": {
          "email": "Correo electrónico",
          "emailError": "",
          "password": "Contraseña",
          "remember": "Recordar este dispositivo",
          "forgotPassword": "¿Olvidaste tu contraseña?",
          "enter": "Iniciar sesión",
          "passwordMessage": "Mínimo 8 caracteres"
        },
        "emailVerification": {
          "hello": "¡Hola!",
          "redirect": "¡Serás redirigido!"
        },
        "error": {
          "notFound": "¡Página no encontrada!",
          "back": "Regresar"
        },
        "passwordResetSent": {
          "title": "¡Éxito!",
          "description": "¡Se ha enviado un enlace para crear una nueva contraseña a tu correo electrónico!",
          "back": "Regresar al inicio de sesión"
        },
        "forgotPassword": {
          "title": "¿Olvidaste tu contraseña?",
          "description": "Por favor, introduce el correo electrónico asociado con tu cuenta y te enviaremos un correo con el enlace para crear una nueva contraseña.",
          "changePassword": "Cambiar Contraseña",
          "back": "Regresar al inicio de sesión"
        },
        "resetPassword": {
          "title": "Restablece tu contraseña",
          "description": "Para restablecer tu contraseña, crea una nueva contraseña con al menos 8 caracteres.",
          "insertPassword": "Introduce tu nueva contraseña",
          "passwordMessage": "Mínimo 8 caracteres",
          "confirmPasswordMessage": "La nueva contraseña no coincide con la anterior",
          "confirmPassword": "Confirma tu nueva contraseña",
          "redefinePassword": "Restablecer Contraseña"
        },
        "signUp": {
          "welcomeTo": "Bienvenido a",
          "hasAccount": "¿Ya tienes una cuenta?",
          "enter": "Iniciar sesión",
          "back": "Regresar",
          "next": "Siguiente",
          "finish": "Finalizar",
          "confirmPassword": "Confirmar contraseña",
          "passwordMessage": "Mínimo 8 caracteres"
        },
        "signUpForm": {
          "cta": "Obtén tu propia tienda",
          "name": "Nombre completo*",
          "email": "Correo electrónico*",
          "phone": "Teléfono*",
          "invalidPhone": "Teléfono inválido",
          "invalidName": "Nombre inválido",
          "invalidMail": "Correo electrónico inválido",
          "privacy": "Al enviar mi información, acepto la Política de privacidad."
        }
      },
      typ: {
        title: "¡Gracias por elegir {{builderName}}! Estamos emocionados de tenerte con nosotros.",
        p1: "Estás a solo 30 minutos de lanzar tu tienda preconfigurada",
        p2: "Pronto recibirás por correo electrónico las credenciales que necesitas para acceder a la plataforma",
        button: "Iniciar sesión",
        getStarted: "Cómo Empezar:",
        point1: "Haz clic en el botón 'Iniciar sesión' para acceder a la plataforma Shop Build Pro.",
        point2: "Utiliza las credenciales enviadas a tu dirección de correo electrónico para iniciar sesión en la plataforma.",
        point3: "Encontrarás una guía completa paso a paso diseñada para guiarte en el proceso de configuración de tu tienda. Es simple, rápido y está diseñada para poner tu tienda en funcionamiento en menos de 30 minutos."
      },
      store: {
        welcome: {
          title: "¡Vamos a crear tu tienda online!",
          paragraph: "¡Genial tenerte aquí! En sólo 3 sencillos pasos, tendrás tu propia tienda profesional, lista para empezar a vender. Mira este vídeo rápido y descubre cómo!",
          button: "¡Vamos!"
        },
        wizard: {
          pageTitle: "",
          pageDescription: "",
          continue: "Continuar",
          back: "Volver",
          steps: {
            "niche": "Nicho",
            "palette": "Paleta",
            "banner": "Banner",
            "account": "Cuenta",
            "plan": "Plan",
            "store": "Tienda",
            "shopify": "Crear una cuenta",
            "app": "Instalar aplicación",
            "success": "Para empezar"
          },
          pages: {
            niche: {
              title: "Elige la categoría de tu tienda",
              miscellaneous: "Varios",
              fitness: "Fitness",
              health: "Salud",
              tech: "Tecnología",
              pet: "Mascotas",
              kids: "Niños",
              home: "Hogar y Cocina"
            },
            palette: {
              title: "Elige una combinación para tu tienda"
            },
            banner: {
              title: "Elige el fondo para el banner de tu página de inicio"
            },
            account: {
              queue: {
                title: "¡Estás en la cola!",
                estimated: "Tiempo estimado de espera:",
                minute: "1 minuto",
                hold: "Mantén esta página abierta y no la cierres hasta que finalice la creación.",
                warning: "¡Mantén esta página abierta y no la cierres hasta que finalice la creación!"
              },
              install: {
                title: "Tu cuenta se está creando automáticamente",
                message: "En menos de 10 minutos, tu cuenta estará lista. ¡Tu camino hacia el éxito acaba de comenzar!",
                installMessage1: "Inicializando...",
                installMessage2: "Configurando los datos de la cuenta...",
                installMessage3: "Insertando correo electrónico y contraseña...",
                installMessage4: "Configurando idiomas y moneda...",
                installMessage5: "Creando cuenta en Shopify...",
                installMessage6: "Creando nueva tienda en Shopify...",
                installMessage7: "Guardando dominio de la tienda...",
                installMessage8: "Creando conexión con la tienda...",
                installMessage9: "Habilitando los permisos necesarios...",
                installMessage10: "Verificando la configuración...",
                installMessage11: "Guardando información de inicio de sesión...",
                installMessage12: "Estamos casi listos...",
                installMessage13: "Finalizando...",
                warning: "¡Mantén esta página abierta y no la cierres hasta que finalice la creación!"
              }
            },
            plan: {
              warning: "¡Mantén esta página abierta y no la cierres hasta que finalice la creación!",
              title: "Mira el video abajo para dar los próximos pasos y desbloquear tu curso completo de forma gratuita.",
              email: "Correo electrónico:",
              password: "Contraseña:",
              url: "URL:",
              button: "Acceder al plan",
              modal: {
                error: {
                  title: "Error al verificar el plan",
                  description: "Duis mollis, est non commodo luctus, nisi erat porttitor ligula.",
                  button: "Verificar el plan",
                  back: "Volver"
                },
                success: {
                  title: "Plan verificado con éxito",
                  button: "Continuar"
                },
                verify: {
                  title: "Verifica el plan",
                  description: "Duis mollis, est non commodo luctus, nisi erat porttitor ligula.",
                  button: "Verificar el plan"
                },
                shopify: {
                  title: "Acabas de obtener 3 días para probar tu tienda en Shopify.",
                  description: "Activa el plan y asegura la promoción pagando solo $1 en el primer mes."
                }
              }
            },
            store: {
              queue: {
                title: "¡Estás en la cola!",
                minute: "1 minuto",
                estimated: "Tiempo estimado de espera:",
                hold: "Mantén esta página abierta y no la cierres hasta que finalice la creación.",
                warning: "¡Mantén esta página abierta y no la cierres hasta que finalice la creación!"
              },
              install: {
                title: "Instalación del tema y producto",
                message: "En menos de 3 minutos, tu tienda estará lista. ¡Tu camino hacia el éxito acaba de comenzar!",
                warning: "¡Mantén esta página abierta y no la cierres hasta que finalice la creación!",
                installMessage1: "Inicializando...",
                installMessage2: "Instalando tema de la tienda...",
                installMessage3: "Configurando la paleta de colores...",
                installMessage4: "Configurando los banners...",
                installMessage5: "Creando páginas de términos y políticas...",
                installMessage6: "Creando las categorías...",
                installMessage7: "Instalando productos...",
              }
            },
            "shopify": {
              "title": "Paso 1: Vamos a crear tu cuenta en Shopify",
              "firstTitle": "1️⃣ Sigue estas instrucciones simples:",
              "firstLi1": "Haz clic en el botón “Abrir Shopify\" abajo",
              "firstLi2": "Se abrirá una nueva ventana",
              "firstLi3": "Mantén esta página abierta también",
              "shopifyButton": "Abrir Shopify",
              "secondTitle": "2️⃣ Crea tu cuenta",
              "secondLi1": "Ingresa tu correo electrónico (usa uno que nunca hayas usado en Shopify)",
              "secondLi2": "Haz clic en \"Iniciar prueba gratis\"",
              "secondAlert1": "Cuando aparezca la opción de país, recuerda seleccionar ",
              "secondAlert2": "De lo contrario, tu tienda no se configurará correctamente",
              "thirdTitle": "3️⃣ Configuración rápida",
              "thirdLi1": "Siempre que veas \"Saltar\", puedes hacer clic",
              "thirdLi2": "Elige \"Continuar con Email\"",
              "thirdLi3": "Crea tu contraseña",
              "fourthTitle": " 4️⃣ Haga clic en el botón \"Seleccionar plan\" en Shopify",
              "fifthTitle": "5️⃣ Elija el plan \"Basic\" (Mensual)",
              "fifthLi1": "⁠Obtendrá 3 días de prueba gratuita.",
              "fifthLi2": "Después de la prueba, los primeros 3 meses costarán solo $1 cada uno.",
              "sixthTitle": "6️⃣ Cuando se le solicite la dirección de su negocio, busque rápidamente en Google cualquier dirección válida del país elegido.",
              "sixthLi1": "No se preocupe, esta dirección no será pública.",
              "seventhTitle": "7️⃣ Ingrese su Información de Pago (Tarjeta de Crédito)",
              "eigthTitle": "8️⃣ Haga clic en \"Suscribirse\" para confirmar el plan",
              "eigthAlert": "Nota: Sin activar un plan, no podemos personalizar su tienda, quitar la contraseña ni publicar productos a la venta. Este paso es obligatorio para continuar.",
              "ninethTitle": "9️⃣  ¡Listo!",
              "ninethLi1": "Regrese a esta página.",
              "ninethLi2": "Haga clic en el botón \"Continuar\" que se encuentra más abajo."
            },
            "app": {
              "title": "Paso 2: Vamos a instalar ProStore AI",
              "firstTitle": "1️⃣ Instala la aplicación",
              "firstLi1": "Haz clic en el botón \"Instalar app\" abajo",
              "firstLi2": "Se abrirá una nueva ventana",
              "firstLi3": "Mantén esta página abierta también",
              "shopifyButton": "Instalar ProStore AI",
              "secondTitle": "2️⃣ Confirma la instalación",
              "secondLi1": "En la nueva ventana, haz clic en \"Instalar\"",
              "secondLi2": "Espera unos segundos",
              "thirdTitle": "3️⃣ Tu código especial",
              "thirdLi1": "Haz clic en el código de arriba para copiarlo",
              "thirdLi2": "Pega el código en la app ProStore AI",
              "thirdLi3": "¡Listo! ✨",
              "thirdAlert": "Este es un código único. Guárdalo en un lugar seguro. Si necesitas ayuda de nuestro soporte, es posible que te lo soliciten.",
              "fourthTitle": "4️⃣ Finalizar",
              "fourthLi1": "Vuelve a esta página",
              "fourthLi2": "Haz clic en el botón \"Continuar\" abajo",
              "codeCopied": "¡Código copiado con éxito!"
            },
            "success": {
              "title": "Paso 3: ¡Tu tienda está casi lista! 🎉",
              "description": "¡Felicidades! Ahora mira el video de abajo para entender cómo personalizar tu tienda:",
              "support": "Contactar al soporte",
              "app": "Acceder a la app"
            }
          }
        }
      },
      contact: {
        title: "¿Necesitas ayuda? Contáctanos",
        text: "Nuestro equipo de soporte puede ayudarle con cualquier pregunta relacionada con la creación de su tienda. No dude en preguntar cualquier cosa; nuestro tiempo medio de respuesta es de 2 horas durante el horario laboral en días laborables.",
        name: "Nombre",
        email: "Correo Electrónico",
        message: "Mensaje",
        button: "Enviar",
        success: "Mensaje enviado exitosamente.",
        successText: "Pronto recibirás un correo de respuesta. ¡Gracias!"
      }
    }
  },
  "en": {
    translation: {
      errorMessages: {
        "domainError": "Domain does not exist or is already in use.",
        "tokenError": "Invalid token, check app scopes.",
        "planError": "Invalid Shopify plan. Please select the Shopify or Advanced option to advance"
      },
      authentication: {
        login: {
          "email": "Email",
          "emailError": "Invalid email",
          "password": "Password",
          "remember": "Remember this device",
          "forgotPassword": "Forgot your password?",
          "enter": "Sign in",
          "passwordMessage": "Minimum 8 characters"
        },
        emailVerification: {
          "hello": "Hello!",
          "redirect": "You will be redirected!"
        },
        error: {
          "notFound": "Page not found!",
          "back": "Go Back"
        },
        passwordResetSent: {
          "title": "Success!",
          "description": "A link to create a new password has been sent to your email!",
          "back": "Go back to Login"
        },
        forgotPassword: {
          "title": "Forgot your password?",
          "description": "Please enter the email associated with your account and we will send you an email with the link to create a new password.",
          "changePassword": "Change Password",
          "back": "Go back to Login"
        },
        resetPassword: {
          "title": "Reset your password",
          "description": "To reset your password, create a new password with at least 8 characters.",
          "insertPassword": "Enter your new password",
          "passwordMessage": "Minimum 8 characters",
          "confirmPasswordMessage": "New password does not match the previous one",
          "confirmPassword": "Confirm your new password",
          "redefinePassword": "Reset Password"
        },
        signUp: {
          "welcomeTo": "Welcome to",
          "hasAccount": "Already have an account?",
          "enter": "Sign in",
          "back": "Go back",
          "next": "Next",
          "finish": "Finish",
          "confirmPassword": "Confirm password",
          "passwordMessage": "Minimum 8 characters"
        },
        signUpForm: {
          "cta": "Get your own store",
          "name": "Full Name*",
          "email": "Email*",
          "phone": "Phone*",
          "invalidPhone": "Invalid Phone",
          "invalidName": "Invalid Name",
          "invalidMail": "Invalid E-mail",
          "privacy": "By submitting my information, I agree to the Privacy Policy."
        }
      },
      typ: {
        title: "Thank you for choosing {{builderName}}! We're thrilled to have you with us.",
        p1: "You're just 30 minutes away from launching your pre-built store",
        p2: "You will soon receive the credentials you need to access the platform by e-mail",
        button: "Sign in",
        getStarted: "How to Get Started:",
        point1: "Click on the 'Sign In' button to access the Shop Build Pro platform.",
        point2: "Use the credentials sent to your e-mail address to log in to the platform.",
        point3: "You will find a comprehensive step-by-step guide designed to walk you through the process of setting up your store. It's simple, quick, and meant to get your store up and running in under 30 minutes."
      },
      "store": {
        "welcome": {
          "title": "Let's create your online store!",
          "paragraph": "Great to have you here! In just 3 simple steps, you'll have your own professional store, ready to start selling. Watch this quick video and find out how!",
          "button": "Let's Go!"
        },
        "wizard": {
          "pageTitle": "",
          "pageDescription": "",
          "continue": "Continue",
          "back": "Back",
          "steps": {
            "niche": "Niche",
            "palette": "Palette",
            "banner": "Banner",
            "account": "Account",
            "plan": "Plan",
            "store": "Store",
            "shopify": "Create an account",
            "app": "Install app",
            "success": "Get started"
          },
          "pages": {
            "niche": {
              "title": "Choose your store category",
              "miscellaneous": "Miscellaneous",
              "fitness": "Fitness",
              "health": "Health",
              "tech": "Tech",
              "pet": "Pet",
              "kids": "Kids",
              "home": "Home & Kitchen"
            },
            "palette": {
              "title": "Choose a color palette for your store"
            },
            "banner": {
              "title": "Choose a background for your homepage banner"
            },
            "account": {
              "queue": {
                "title": "You're in the queue!",
                "estimated": "Estimated wait time:",
                "minute": "1 minute",
                "hold": "Please keep this page open and do not close it until the setup is complete.",
                "warning": "Please keep this page open and do not close it until the setup is complete!"
              },
              "install": {
                "title": "Your account is being created automatically",
                "message": "In less than 10 minutes, your account will be ready. Your path to success is just beginning.",
                "installMessage1": "Initializing...",
                "installMessage2": "Setting up account data...",
                "installMessage3": "Entering email and password...",
                "installMessage4": "Configuring languages and currency...",
                "installMessage5": "Creating Shopify account...",
                "installMessage6": "Creating new store on Shopify...",
                "installMessage7": "Saving store domain...",
                "installMessage8": "Creating connection with store...",
                "installMessage9": "Enabling necessary scopes...",
                "installMessage10": "Verifying settings...",
                "installMessage11": "Saving login information...",
                "installMessage12": "We're almost there...",
                "installMessage13": "Finishing...",
                "warning": "Please keep this page open and do not close it until the setup is complete!"
              }
            },
            "plan": {
              "warning": "Please keep this page open and do not close it until the setup is complete!",
              "title": "Watch the video below to take the next steps and unlock your complete course for free.",
              "email": "Email:",
              "password": "Password:",
              "url": "URL:",
              "button": "Access the plan",
              "modal": {
                "error": {
                  "title": "Error verifying plan",
                  "description": "Duis mollis, est non commodo luctus, nisi erat porttitor ligula.",
                  "button": "Verify plan",
                  "back": "Back"
                },
                "success": {
                  "title": "Plan successfully verified",
                  "button": "Continue"
                },
                "verify": {
                  "title": "Verify the plan",
                  "description": "Duis mollis, est non commodo luctus, nisi erat porttitor ligula.",
                  "button": "Verify plan"
                },
                "shopify": {
                  "title": "You just got 3 days to test your store on Shopify.",
                  "description": "Activate the plan and secure the promotion by paying only $1 in the first month."
                }
              }
            },
            "store": {
              "queue": {
                "title": "You're in the queue!",
                "minute": "1 minute",
                "estimated": "Estimated wait time:",
                "hold": "Please keep this page open and do not close it until the setup is complete.",
                "warning": "Please keep this page open and do not close it until the setup is complete!"
              },
              "install": {
                "title": "Theme and product installation",
                "message": "In less than 3 minutes, your store will be ready. Your path to success is just beginning.",
                "warning": "Please keep this page open and do not close it until the setup is complete!",
                "installMessage1": "Initializing...",
                "installMessage2": "Installing store theme...",
                "installMessage3": "Configuring color palette...",
                "installMessage4": "Configuring banners...",
                "installMessage5": "Creating terms and policies pages...",
                "installMessage6": "Creating categories...",
                "installMessage7": "Installing products..."
              }
            },
            "shopify": {
              "title": "Step 1: Let's create your Shopify account",
              "firstTitle": "1️⃣ Follow these simple steps:",
              "firstLi1": "Click the “Open Shopify” button below",
              "firstLi2": "A new window will open",
              "firstLi3": "Keep this page open as well",
              "shopifyButton": "Open Shopify",
              "secondTitle": "2️⃣ Create your account",
              "secondLi1": "Enter your email (use one you've never used with Shopify)",
              "secondLi2": "Click on \"Start free trial\"",
              "secondAlert1": "When the country option appears, remember to select ",
              "secondAlert2": "Otherwise, your store won't be set up correctly",
              "thirdTitle": "3️⃣ Quick setup",
              "thirdLi1": "Whenever you see \"Skip\", you can click it",
              "thirdLi2": "Choose \"Continue with Email\"",
              "thirdLi3": "Create your password",
              "fourthTitle": " 4️⃣  Click the \"Select Plan\" button on Shopify",
              "fifthTitle": "5️⃣ ⁠Choose the \"Basic\" (Monthly) plan",
              "fifthLi1": "⁠You’ll have a 3-day free trial.",
              "fifthLi2": "After the trial, your first 3 months will cost just $1 each.",
              "sixthTitle": "6️⃣ When asked for your business address, quickly look up any valid address from the chosen country using Google.",
              "sixthLi1": "⁠Don’t worry, this address won’t be publicly visible.",
              "seventhTitle": "7️⃣ ⁠Enter Your Payment Information (Credit Card)",
              "eigthTitle": "8️⃣ Click \"Subscribe\" to confirm your plan",
              "eigthAlert": "Note: Without activating a plan, we can’t customize your store, remove the password, or list products for sale. This step is mandatory to proceed.",
              "ninethTitle": "9️⃣ You’re All Set",
              "ninethLi1": "Return to this page.",
              "ninethLi2": "Click the \"Continue\" button below."
            },
            "app": {
              "title": "Step 2: Let's install ProStore AI",
              "firstTitle": "1️⃣ Install the app",
              "firstLi1": "Click the \"Install app\" button below",
              "firstLi2": "A new window will open",
              "firstLi3": "Keep this page open as well",
              "shopifyButton": "Install ProStore AI",
              "secondTitle": "2️⃣ Confirm installation",
              "secondLi1": "In the new window, click on \"Install\"",
              "secondLi2": "Wait a few seconds",
              "thirdTitle": "3️⃣ Your special code",
              "thirdLi1": "Click the code above to copy it",
              "thirdLi2": "Paste the code into the ProStore AI app",
              "thirdLi3": "All set! ✨",
              "thirdAlert": "This is a unique code. Save it somewhere safe. If you need assistance from our support team, they may ask for this code.",
              "fourthTitle": "4️⃣ Finish up",
              "fourthLi1": "Return to this page",
              "fourthLi2": "Click the \"Continue\" button below",
              "codeCopied": "Code copied successfully!"
            },
            "success": {
              "title": "Step 3: Your store is almost ready! 🎉",
              "description": "Congratulations! Now watch the video below to learn how to customize your store:",
              "support": "Contact Support",
              "app": "Access App"
            }

          }
        }
      },
      contact: {
        title: "Need help? Contact us",
        text: "Our support team can assist you with any questions related to creating your store. Feel free to ask anything; our average response time is within 2 hours during business hours on weekdays",
        name: "Name",
        email: "Email",
        message: "Message",
        button: "Submit",
        success: "Message sent successfully!",
        successText: "You will soon receive a response email. Thank you!"
      }
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'pt-BR',
    lng: process.env.REACT_APP_LOCALE,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
